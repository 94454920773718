import React, { useState, useEffect } from "react"
import { useCustomerSocialLogin } from "../../../../hooks/useCustomer"

import { useImage } from "../../../../hooks/useImage"

const LoadingText = [
  "Verifying Account...",
  "Loading Customer Details...",
  "Completing Login...",
]

export const withAuth =
  Component =>
  ({ name = "Auth", page }) => {
    const { getGatsbyImage } = useImage()

    const [loadingText, setLoadingText] = useState(0)

    const { socialLogin } = useCustomerSocialLogin()

    useEffect(() => {
      socialLogin()
    }, [socialLogin])

    useEffect(() => {
      setTimeout(() => {
        setLoadingText(1)
        setTimeout(() => {
          setLoadingText(2)
        }, 3000)
      }, 2000)
    }, [])

    const { image: originalImage } = page || {}

    const image = getGatsbyImage(originalImage)

    Component.displayName = name
    return <Component image={image} loadingText={LoadingText[loadingText]} />
  }
