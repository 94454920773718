import React from "react"
import { navigate } from "gatsby"

import { useApp } from "../hooks/useApp"
import { useCore } from "../hooks/useCore"
import { useCustomerAccessToken, useCustomerContext } from "../hooks/useCustomer"

export const withAuthentication = Component => ({ location, ...props }) => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    config: {
      settings: { keys, params, routes },
    },
  } = useApp()

  const { customer } = useCustomerContext()

  const { getCustomer } = useCustomerAccessToken()

  const customerTokens = storage.get(keys?.customer)

  if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
    storage.remove(keys?.customer)
    if (isBrowser) {
      const route = `${routes?.LOGIN}${location.pathname !== routes.DASHBOARD ? `?${params?.continue}=${location.pathname}` : ``}`
      navigate(route)
    }
  }

  if (!customer?.id) getCustomer()

  return <Component location={location} {...props} />
}

export const withoutAuthentication = Component => ({ ...props }) => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const customerTokens = storage.get(keys?.customer)

  if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
    if (isBrowser) {
      navigate(routes?.DASHBOARD)
    }
  }

  return <Component {...props} />
}
