import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../../hoc/withAuthentication"
import { Auth as Page } from "../../../components/Account/Login/Auth/Auth"

import { useHotjar } from '../../../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageAccountLogin {
      title
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = withoutAuthentication(({ data, ...props }): JSX.Element => { 
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("auth_page")
  
  return <Page {...props} {...data} />
})
export default Component
